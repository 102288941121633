<template>
  <vue-final-modal
    class="modal-confirm-session-logout"
    :clickToClose="false"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="modal-confirm-session-logout__container">
      <atomic-icon
        class="modal-confirm-session-logout__close"
        id="close"
        @click="closeDialog"
      />

      <div class="modal-confirm-session-logout__title">
        {{ getContent(currentLocaleData, defaultLocaleData, 'confirmModalLogout.title') }}
      </div>

      <div class="modal-confirm-session-logout__description">
        {{ getContent(currentLocaleData, defaultLocaleData, 'confirmModalLogout.description') }}
      </div>

      <div class="modal-confirm-session-logout__actions">
        <button-base
          type="gray"
          size="md"
          @click="closeDialog()">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.cancel') }}
        </button-base>

        <button-base
          type="primary"
          size="md"
          @click="emit('confirm')"
        >
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.confirm') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import { ModalName } from "@skeleton/consts/modals";
const {
  layoutData,
  defaultLocaleLayoutData,
} = storeToRefs(useGlobalStore());

const contentParams = {
  contentKey: 'profileSessionsContent',
  contentRoute: ['profile', 'sessions'],
  isPage: true,
};

const emit = defineEmits(['confirm']);

const { getContentData } = useContentLogic(contentParams);
const { currentLocaleData, defaultLocaleData } = await getContentData();

const { getContent } = useProjectMethods();
const { closeModal } = useModalStore();

const closeDialog = () => {
  closeModal(ModalName.CONFIRM_SESSION_LOGOUT)
}
</script>

<style src="~/assets/styles/components/modal/confirm-session-logout.scss" lang="scss" />
